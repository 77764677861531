<template>
<section class="blog__content_wrapper">
    <div
        v-if="isDetailArticle && articleDetail"
        class="blog__content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12
                            blog__content_title
                            articles_content_margin_b_size_m">
                    {{articleDetail.title}}
                </div>
                <template v-for="(paragraphItem, index) of articleDetail.paragraphs"
                          :key="index">

                    <template v-if="index === 0 && articleDetail.image">
                        <div class="container-fluid">
                            <div class="row articles_content_margin_b_size_l">
                                <div class="col-xl-6 col-lg-12">
                                    <div class="blog__content_description pl-0"
                                         v-html="paragraphItem">
                                    </div>
                                </div>

                                <div class="col-xl-6
                                    col-lg-12
                                    d-flex
                                    justify-content-end
                                    align-items-start
                                    blog_content_img_margin_t_size_m">
                                    <img
                                        class="w-100"
                                        :src="articleDetail.image" alt=""/>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div v-else
                         class="container-fluid">
                        <div class="row articles_content_margin_b_size_l">
                            <div class="col-12">
                                <div class="blog__content_description pl-0"
                                     v-html="paragraphItem">
                                </div>
                            </div>
                        </div>
                    </div>

                </template>
            </div>
        </div>
    </div>

    <div v-else
         class="blog__content articles_content_margin_b_size_l">

        <template v-for="(article, index) of articleList"
                     :key="index">
        <div
            class="blog__content_article cursor-pointer"
            @click="selectArticle(article.id)">
            <div class="container-fluid h-100">
                <div class="row">
                    <div class="col-xl-6 col-lg-12 order-2"
                         v-bind:class="{ 'order-xl-1': isEvenNumber(index),
                                         'order-xl-2': !isEvenNumber(index)}">

                        <div class="blog__content_title
                                    articles_content_margin_b_size_m">
                            {{article.title}}
                        </div>
                        <div class="d-flex">
                            <div class="blog__content_date
                                                d-none
                                                d-lg-flex
                                                justify-content-end
                                                blog_content_vertical_item">
                                {{formatDate(article.created_timestamp)}}
                            </div>
                            <div class="blog__content_description"
                                 v-html="article.description">
                            </div>
                        </div>
                        <div class="blog__content_date
                                            d-flex
                                            d-lg-none
                                            mt-2
                                            justify-content-end">
                            {{formatDate(article.created_timestamp)}}
                        </div>
                    </div>

                    <div class="blog__content_img
                                col-xl-6
                                col-lg-12
                                order-1
                                d-none
                                d-lg-block
                                d-flex
                                justify-content-end align-items-start"
                         v-bind:class="{ 'order-xl-2': isEvenNumber(index),
                                         'order-xl-1': !isEvenNumber(index) }">
                        <img
                            class="w-100"
                            :src="article.image" alt=""/>
                    </div>
                </div>
            </div>
        </div>
        </template>
    </div>
</section>
</template>

<script>
export default {
  name: 'Blog',

  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'showArticles',
  },

  methods: {
    getList() {
      this.$http.get('/api/article/list/').then((response) => {
        this.articleList = response.data;
      });
    },
    getDetailArticle(id) {
      this.$http.get(`/api/article/${id}/`).then((response) => {
        this.articleDetail = response.data;
      });
    },

    selectArticle(id) {
      this.$router.push({ query: { article: id } });
    },
    showArticles() {
      const { article } = this.$route.query;
      if (article) {
        this.isDetailArticle = true;
        this.articleDetail = {};
        this.getDetailArticle(article);
      } else {
        this.isDetailArticle = false;
        this.articleList = [];
        this.getList(article);
      }
    },

    formatDate(dateString) {
      const date = new Date(Date.parse(dateString));
      const dateFormat = date.toLocaleString('default', { day: '2-digit', month: '2-digit', year: 'numeric' });

      return dateFormat.split('/').join('-');
    },

    isEvenNumber(index) {
      return index % 2 === 0;
    },
  },
  mounted() {
    this.showArticles();
  },
  data() {
    return {
      isDetailArticle: false,
      articleList: [],
      articleDetail: {},
    };
  },
};
</script>
